import React from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import "./index.scss"

const LivePage = ({ data }) => {
  var futureDates = []
  var pastDates = []

  data.allContentfulTermine.nodes.map(termin => {
    console.log("termin: ", new Date(termin.dateAndTime))
    console.log("jetzt: ", new Date())
    if (new Date(termin.dateAndTime) > new Date()) {
      futureDates.push(termin)
    } else {
      pastDates.push(termin)
    }
    return null
  })

  return (
    <Layout title="Live">
      <SEO title="Live | Leonie Freudenberger" />
      <article className="live">
        <section>
          {futureDates.length > 0 && (
            <h4 className="upcoming-performances">Upcoming</h4>
          )}
          {futureDates.map(termin => (
            <div
              className="live-entry fade-in-1"
              data-sal="fade"
              data-sal-duration="1000"
            >
              <div className="date-time">
                <div className="month">
                  {new Intl.DateTimeFormat("en-US", { month: "short" }).format(
                    new Date(termin.dateAndTime)
                  )}
                </div>
                <div className="day">
                  {new Intl.DateTimeFormat("en-US", { day: "numeric" }).format(
                    new Date(termin.dateAndTime)
                  )}
                </div>
                <div className="weekday-long">
                  {new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(
                    new Date(termin.dateAndTime)
                  )}
                </div>
                <div className="weekday-short">
                  {new Intl.DateTimeFormat("en-US", {
                    weekday: "short",
                  }).format(new Date(termin.dateAndTime))}
                </div>
              </div>
              <div className="details">
                <a
                  href={termin.link}
                  className="live-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="time">
                    {new Intl.DateTimeFormat("en-gb", {
                      hour: "numeric",
                      minute: "2-digit",
                    }).format(new Date(termin.dateAndTime))}
                  </span>
                  <span>{" · " + termin.location}</span>
                </a>
                <div className="title">{termin.title}</div>
                {termin.description && (
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: termin.description.childMarkdownRemark.html,
                    }}
                  ></div>
                )}
              </div>
            </div>
          ))}
        </section>
        <section className="past-performances">
          {pastDates.length > 0 && <h4>Past Performances</h4>}
          {pastDates.map(termin => (
            <div
              className="live-entry"
              data-sal="fade"
              data-sal-duration="1000"
            >
              <div className="date-time">
                <div className="month">
                  {new Intl.DateTimeFormat("en-US", { month: "short" }).format(
                    new Date(termin.dateAndTime)
                  )}
                </div>
                <div className="day">
                  {new Intl.DateTimeFormat("en-US", { day: "numeric" }).format(
                    new Date(termin.dateAndTime)
                  )}
                </div>
                <div className="weekday-long">
                  {new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(
                    new Date(termin.dateAndTime)
                  )}
                </div>
                <div className="weekday-short">
                  {new Intl.DateTimeFormat("en-US", {
                    weekday: "short",
                  }).format(new Date(termin.dateAndTime))}
                </div>
              </div>
              <div className="details">
                <a
                  href={termin.link}
                  className="live-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="time">
                    {new Intl.DateTimeFormat("en-gb", {
                      hour: "numeric",
                      minute: "2-digit",
                    }).format(new Date(termin.dateAndTime))}
                  </span>
                  <span>{" · " + termin.location}</span>
                </a>
                <div className="title">{termin.title}</div>
                {termin.description && (
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: termin.description.childMarkdownRemark.html,
                    }}
                  ></div>
                )}
              </div>
            </div>
          ))}
        </section>
      </article>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulTermine(sort: { fields: dateAndTime, order: DESC }) {
      nodes {
        dateAndTime
        description {
          childMarkdownRemark {
            html
          }
        }
        link
        location
        title
      }
    }
  }
`

export default LivePage
